/*
General estilo para el modal de rango de fechas
*/
.MDDialogContent * {
	font-family: 'Arial', sans-serif !important;
	font-size: 12px !important;
	line-height: 12px !important;
}

.MDDialogContent *:focus {
	outline: none !important;
}

/*
Estilo para el calendario
*/

.MDDialogContent .rdrMonths.rdrMonthsHorizontal .rdrMonth {
	padding-bottom: 0 !important;
}

.MDDialogContent .rdrMonths.rdrMonthsVertical .rdrMonth {
	padding-bottom: 0 !important;
}

/*
Selected define range
*/
.MDDialogContent .rdrDefinedRangesWrapper {
	border: solid 1px #3f51b5 !important;
}

.MDDialogContent .rdrDefinedRangesWrapper * {
	background: #3f51b5 !important;
	color: white !important;
}

.MDDialogContent .rdrDefinedRangesWrapper .rdrStaticRangeSelected {
	background: #33428d !important;
	color: white !important;
	font-weight: 900 !important;
}

.MDDialogContent .rdrDefinedRangesWrapper .rdrStaticRange {
	border: 1px solid #3f51b5 !important;
}
